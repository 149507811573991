function InfoPopUp(props) {
    const currentDate = new Date();
    const startDate = new Date('2025-01-29T00:00:00');
    const threeMonths = new Date(startDate.setMonth(startDate.getMonth() + 3));
    return (
        currentDate < threeMonths ? (<div className={'info_popup'}>
            <p className={'pop_up_title'}>{props.title}</p>
            <p>{props.text}</p>
        </div>) : null
    )
}

export default InfoPopUp;