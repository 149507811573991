import React, {useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import './error_message.css';
import {Button} from "react-bootstrap"
import {markBillAuthorizedForPayment, updateBillForPayment} from "../../../features/DamageBill/bill.actions";
import {useDispatch, useSelector} from "react-redux";
import {convertForeignToDomesticCurrency} from "../../../utils/damageItemsCalculations";
import toast from "react-hot-toast";

const getInconsistenciesErrorMessages = ({bill}) => {
    return {
        1: 'PAYOUT_INCONSISTENCY_OUTSIDE_OFFICE_HOURS (ooh)',
        10: 'PAYOUT_INCONSISTENCY_GOODWILL (K)',
        20: `${bill && bill.damage_bill_id ? `Achtung, in vorliegender Garantie wurde bereits ein Schaden auf die Belegnummer ${bill.damage_bill_id} ausbezahlt.` : 'Achtung, in vorliegender Garantie wurde bereits ein Schaden ausbezahlt.'}`,
        21: 'Achtung, der Betrag mit dieser Summe wurde bereits einmal ausbezahlt.',
        22: 'Gleiche Belegnummer wurde bei diesem Zahlungsempfänger bereits verwendet.',
        30: 'PAYOUT_INCONSISTENCY_INVOICE_ABROAD_COUNTRY (AuZa)',
        40: 'PAYOUT_INCONSISTENCY_ADDITIONAL_TAX_FOR_SOME_CLIENTS (MwSt)',
        50: 'Ist es in vorliegendem Fall richtig, dass der Schaden ohne Mehrwertsteuer ausbezahlt wird?',
        51: 'Ist es in vorliegendem Fall richtig, dass der Schaden ohne Mehrwertsteuer ausbezahlt wird?',
        52: 'Ist es in vorliegendem Fall richtig, dass der Schaden ohne Mehrwertsteuer ausbezahlt wird?',
        60: 'ACHTUNG: Es ist nur ein Bauteil zugeordnet. Abrechnung trotzdem durchführen?'
    };
}

const validationErrorMessages = {
    1: 'Sie sind nicht berechtigt, eine Zahlung freizugeben. Angemeldeter User ist gleich dem Namen in dieser Zahlung.',
    10: 'CONST_VALIDATE_DAMAGE_BILL_NO_RESPONSE',
    20: 'Der Gesamtbetrag der Rechnung stimmt nicht mit dem Betrag überein, der sich aufgrund der Berechnung ergibt',
    30: 'Sie sind nicht berechtigt, diese Zahlung freizugeben.',
    40: 'Abrechnung nicht möglich, da kein Bauteil zugeordnet ist.',
    50: 'Bitte den Zahlungsempfänger auf sonstige Empfänger ändern!',
    60: 'Bitte Währung wählen',
    70: 'Buchung der Zahlung nicht möglich. Schaden nicht freigegeben in givit.'
}

export function ErrorMessageModal({show, setShow, formRef}) {
    const {
        bill,
        allowPaymentButton,
        allowAuthorizeButton,
        is_damage_closed_by_this_bill,
        validationCodes,
        payout_inconsistencies,
        validation_codes_warning,
        maxPaymentLimit,
        paymentTotal,
        currency,
        fraudMessage
    } = useSelector(state => state.damageBill);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!fraudMessage) toast.error(fraudMessage,{style: {color: "red", fontWeight: "bold"}});
    }, [fraudMessage]);

    const onConfirmPrepare = () => {
        if (formRef && formRef.current) {
            dispatch(
                updateBillForPayment(
                    {
                        billId: bill.damage_bill_id,
                        data: (() => {
                            const data = new FormData(formRef.current);
                            const l = {}
                            for (const pair of data.entries()) {
                                l[pair[0]] = pair[1];
                            }
                            return l;
                        })(),
                        user_notified: 1
                    }
                )
            );
            setShow(false);
        }
    };

    const onConfirmAuthorize = () => {
        dispatch(
            markBillAuthorizedForPayment(
                {
                    billId: bill.damage_bill_id,
                    isMarked: is_damage_closed_by_this_bill,
                    user_notified: 1
                }
            )
        );
        setShow(false);
    }

    return (
        <Modal className={''} show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header closeButton className={'background'}>
                <Modal.Title className={'modal-title'}>Fehler!</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'background text-center'}>
                <div className={'d-flex flex-column align-items-center px-3 pt-2'}>
                    {validationCodes && validationCodes.length > 0 ? validationCodes.map((code, index) => <p
                            key={index}>{validationErrorMessages[code]}</p>) :
                        payout_inconsistencies && payout_inconsistencies !== '' && payout_inconsistencies.split(',').filter(code => code === '20' || code === '21' || code === '22' || code==='40' || code==='50' || code==='51' || code==='52' || code ==='60').map((code, index) =>
                            <p
                                key={index}>{getInconsistenciesErrorMessages({bill})[code]}</p>)}
                    {validationCodes && validationCodes.length === 0 && validation_codes_warning !== '' && validation_codes_warning.split(',').map((code, index) =>
                        <p
                            key={index}>{validationErrorMessages[code]}</p>)}
                </div>
            </Modal.Body>
            {(payout_inconsistencies !== '' || validation_codes_warning !== '') && validationCodes.length === 0 &&
            <Modal.Footer className={'background modal-title'}>
                <p className={'text'}>Mache trotzdem weiter?</p>
                <Button variant={"success"}
                        onClick={allowPaymentButton && maxPaymentLimit < convertForeignToDomesticCurrency(paymentTotal, currency?.rate) ? onConfirmPrepare : allowAuthorizeButton && maxPaymentLimit >= convertForeignToDomesticCurrency(paymentTotal, currency?.rate) && onConfirmAuthorize}>Ja</Button>
                <Button variant={"secondary"} onClick={() => setShow(false)}>Nein</Button>
            </Modal.Footer>}
        </Modal>
    );
}
