// @flow
import * as React from 'react';
import {Button} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux";
import {updateBillForPayment} from "../../features/DamageBill/bill.actions";
import {checkLoggedUser} from "../../features/Authentication/authSlice";

export function PrepareBillForPaymentButton({formRef}) {
    const {bill, damage} = useSelector(state => state.damageBill);
    const dispatch = useDispatch();

    const onClickForm = async () => {
        await dispatch(checkLoggedUser());
        if (formRef && formRef.current) {
            formRef.current.requestSubmit();
        }
        if (formRef && formRef.current) {
            dispatch(
                updateBillForPayment(
                    {
                        billId: bill.damage_bill_id,
                        data: (() => {
                            const data = new FormData(formRef.current);
                            const l = {}
                            for (const pair of data.entries()) {
                                l[pair[0]] = pair[1];
                            }
                            return l;
                        })()
                    }
                )
            )
            // dispatch(updateBillForPayment(bill?.damage_bill_id))

            // dispatch(updateBillForPayment(bill?.damage_bill_id))
        }
    };

    return (<Button variant="primary"
                    size={'sm'}
                    style={{
                        background: '#FF7700',
                        borderColor: '#FF7700',
                        display: damage && (damage.release_status === 0 || damage.release_status === 3) ? 'inline-block' : 'none'
                    }}
                    onClick={onClickForm}>Zur Zahlung anweisen</Button>

    );
}
