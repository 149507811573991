import {BILL_PROCESSING_STATUS_LABELS} from "../../StatusContext";
import * as React from "react";
import {useSelector} from "react-redux";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import logInfoImg from "../../../assets/images/logInfoIcon.svg";
import useApi from "../../../hooks/useApi";
import {getBillLogInfo} from "../../../services/API/damageBill.service";
import {useEffect, useState} from "react";
import dateFormat from "dateformat";
import {getFraudInfo} from "../../../services/API/damageBills.service";

const actions = {
    "getSingleDamagebill": "Open Bill",
    "toggleDamagebillEditLockStatusLock": "Annehmen",
    "toggleDamagebillEditLockStatusUnLock": "Bearbeitung freigeben",
    "updateDamagebill": "Speichern",
    "authorizeDamageBill": "Zur Zahlung freigeben",
    "prepareForPaymentDamageBill": "Zur Zahlung anweisen",
    "resolveDamageBill": "Buchung hinzufügen",
    "customEmailInvoiceReportForBill": "Resend email",
    "getDamagebillOcrFiles": "OCR-JSON-Dateien herunterladen"
}

function DamageInfoStatus({refreshLogsIndicator}) {

    const {bill, damage} = useSelector(state => state.damageBill);
    const {data: logInfo, request: getLogInfo} = useApi(getBillLogInfo);
    const [showTooltip, setShowTooltip] = useState(false);
    const [fraudText, setFraudText] = useState('');
    const {data: fraudInfo, request: getFraudInfoRequest} = useApi(getFraudInfo);

    useEffect(() => {
        damage?.id && getFraudInfoRequest({damageId: damage?.id, billContactReason: bill?.bill_contact_reason});
    }, [damage, bill]);

    useEffect(() => {
        if (fraudInfo && fraudInfo.data) {
            const checkFraud = fraudInfo.data[0];
            if (checkFraud?.fraud_chk) {
                if (checkFraud?.fraud === 1) setFraudText('Betrug im Schaden aktiv');
                else if (checkFraud?.suspected_fraud === 1) setFraudText('Betrugsverdacht');
                else setFraudText('');
            } else setFraudText('');
        }
    }, [fraudInfo]);

    useEffect(() => {
        bill?.damage_bill_id && getLogInfo({id: bill?.damage_bill_id});
    }, [bill, getLogInfo, refreshLogsIndicator]);
    let data_depends_on_release_status = '';

    if (damage?.release_status === 0) {
        data_depends_on_release_status = 'Keine Freigabe notwendig';
    }
    if (damage?.release_status === 1) {
        data_depends_on_release_status = 'Freigabe erforderlich';
    }
    if (damage?.release_status === 2) {
        data_depends_on_release_status = 'Freigabe angef';
    }
    if (damage?.release_status === 3) {
        data_depends_on_release_status = 'Freigabe erteilt';
    }
    if (damage?.release_status === 9) {
        data_depends_on_release_status = ' Freigabe abgelehnt';
    }

    return (
        <div className={'d-flex flex-column align-items-start'} style={{gap: '5px'}}>
        <div className={'status_wrapper'}>
           {bill && bill.processing_status &&
            <div style={{alignSelf: "center", display: 'flex'}}>
                <div className={"Ellipse Ellipse-" + bill.processing_status}
                     title={BILL_PROCESSING_STATUS_LABELS[`${bill.processing_status}`]}
                     style={{float: "left"}}/>
                <div className="info-label" style={{
                    float: "left",
                    marginLeft: "10px"
                }}>{BILL_PROCESSING_STATUS_LABELS[`${bill.processing_status}`]}</div>
            </div>
            }
            <div><OverlayTrigger
                delay={{hide: 200, show: 100}}
                show={showTooltip}
                trigger={['hover', 'focus']}
                overlay={(props) => (
                    <Tooltip {...props} onMouseEnter={() => setShowTooltip(true)}
                             onMouseLeave={() => setShowTooltip(false)} className={'logInfo'}>
                        <table>
                            <thead>
                            <tr>
                                <th>User</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>{logInfo && logInfo.data.map(log => (
                                <tr key={log.id}>
                                    <td>{`${log.user_first_name} ${log.user_last_name}`}</td>
                                    <td style={{minWidth: '150px'}}>{dateFormat(log.date_created.date, "dd.mm.yyyy HH:MM:ss")}</td>
                                    <td style={{minWidth: '170px'}}>{actions[log.route_name]} <br/> {log?.description && `${log?.description}`}</td>
                                </tr>
                            ))}</tbody>
                        </table>
                    </Tooltip>
                )}
                placement="auto"
                defaultShow={false}>
                <img src={logInfoImg} alt={'Log info'} width={25} height={25}
                     onMouseEnter={() => setShowTooltip(true)}
                     onMouseLeave={() => setShowTooltip(false)}/>
            </OverlayTrigger></div>
            <div style={{alignSelf: "center", display: 'flex'}}>
                <div className="info-label" style={{
                    float: "left"
                }}>Schadenstatus Givit: {data_depends_on_release_status}</div>
            </div>
        </div>
            {!!fraudText && <p className={'m-0 font-weight-bold'} style={{color: 'red'}}>{fraudText}</p>}
        </div>
    )
}

export default DamageInfoStatus;